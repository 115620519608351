import { useEffect, useRef } from 'react';
import './CssSwiper.css';

const CssSwiper = () => {
    const logosRef = useRef(null);

    useEffect(() => {
        const logosSlide = logosRef.current.children[0];
        const copy = logosSlide.cloneNode(true);
        logosRef.current.appendChild(copy);
    }, []);

    return (
      <>
        <hr />
        <div className="logos" ref={logosRef}>
          <div className="logos-slide">
            <img
              src={require("../../images/homeImages/logo1.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo2.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo3.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo4.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo5.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo6.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo7.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo8.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo9.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo10.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo11.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo12.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo13.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo14.jpg")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo15.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo16.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo17.png")}
              alt="Logo 1"
            />
            <img
              src={require("../../images/homeImages/logo18.png")}
              alt="Logo 1"
            />
          </div>
        </div>
        <hr />
      </>
    );
};

export default CssSwiper;
