import React, { useState } from "react";
import { toast } from "react-toastify";
// import "./ContactForm.css";
import './Contactform.css'
import { IoIosRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// import ReCAPTCHA from "react-google-recaptcha";

export default function Contactform() {
  // Captcha
  const randomString = Math.random().toString(36).slice(6);
  const [captcha, setCaptcha] = useState(randomString);
  const [inputText, setInputText] = useState();
  const [captchaValid, setCaptchaValid] = useState(true);

  const refreshString = () => {
    setCaptcha(Math.random().toString(36).slice(6));
  };

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.contactNumber.trim()) {
      errors.contactNumber = "Contact Number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.contactNumber)) {
      errors.contactNumber = "Contact Number is invalid";
      isValid = false;
    }

    if (!formData.message) {
      errors.message = ""; // Clear any previous message errors if message is empty
    }
    // if (!formData.message.trim()) {
    //   errors.message = "Message is required";
    //   isValid = false;
    // }
    // if (inputText !== captcha) {
    //   errors.captcha = "Captcha is required";
    //   isValid = false;
    // }

    return { isValid, errors };
  };

  // if (inputText !== captcha) {
  //    errors.captcha = "Captcha is required";
  // //    set= false;
  //  }

  console.log("formadata", formData);
  console.log("error", errors);

  // const submitForm = (e) => {
  //   e.preventDefault();
  //   const { isValid, errors } = validateForm();

  //   if (isValid) {
  //     const config = {
  //       // SecureToken: process.env.REACT_APP_SECURITY_TOKEN,
  //       // To: "yashbaldota@datartinfotech.com",
  //       // From: formData.email,
  //       Host: "smtp.elasticemail.com",
  //       Username: "yashtatiya@datartinfotech.com",
  //       Password: "982BFD409389CF7604BF8757A0345C4FEE19",
  //       To: "yashtatiya@datartinfotech.com",
  //       From: "yashtatiya@datartinfotech.com",
  //       Subject: `New enquiry received form ${formData.fullName}`,
  //       Body: `
  //         Full Name : ${formData.fullName} <br />
  //         Email Id : ${formData.email} <br />
  //         Phone Number : ${formData.contactNumber} <br />
  //         Message : ${formData.message || "No message provided."}
  //       `,
  //     };
  //     console.log("config", config);

  //     if (window.Email) {
  //       window.Email.send(config)
  //         .then(() => {
  //           toast.success(
  //             "Thank You for reaching out to us. We'll get back to you shortly."
  //           );
  //           window.scrollTo(0, 0);
  //           setFormData({
  //             fullName: "",
  //             email: "",
  //             contactNumber: "",
  //             message: "",
  //             captcha: "",
  //           });
  //           setInputText("");
  //           // setCaptcha(null);
  //           setCaptchaValid("");
  //           setErrors({ ...errors, captcha: "" });
  //           handleThankuPage();
  //         })
  //         .catch((err) => {
  //           toast.error("Opps! Something went wrong.");
  //           console.error(err);
  //         });
  //     }
  //   } else {
  //     setErrors(errors);
  //     console.log("Form validation failed");
  //   }
  // };

  const submitForm = async (e) => {
    e.preventDefault();
    const { isValid, errors } = validateForm();

    if (isValid) {
      const data = {
        fullName: formData.fullName,
        email: formData.email,
        contactNumber: formData.contactNumber,
        message: formData.message || "No message provided.",
      };

      try {
        const response = await axios.post(
          `https://server-dot-apenterpirse-test.el.r.appspot.com/sendcontactmail`,
          data
        );

        if (response.status === 200) {
          toast.success(
            "Thank You for reaching out to us. We'll get back to you shortly."
          );
          console.log("responseapi", response);
          window.scrollTo(0, 0);
          setFormData({
            fullName: "",
            email: "",
            contactNumber: "",
            message: "",
            captcha: "",
          });
          setInputText("");
          setCaptchaValid("");
          setErrors({ ...errors, captcha: "" });
          handleThankuPage();
        } else {
          toast.error("Oops! Something went wrong.");
        }
      } catch (err) {
        toast.error("Oops! Something went wrong.");
        console.error(err);
      }
    } else {
      setErrors(errors);
      console.log("Form validation failed");
    }
  };

  const checkCaptcha = (e) => {
    const value = e.target.value;
    setInputText(value);
    if (value === captcha) {
      setCaptchaValid(false);
      errors.captcha = "";
    } else {
      setCaptchaValid(true);
      errors.captcha = "Captcha is required";
    }
    //         if (inputText !== captcha) {
    //       errors.captcha = "Captcha is required";
    //  } else{
    //     errors.captcha = "Captcha is required";
    //  }
  };

  const handleThankuPage = () => {
    navigate("/thank-you");
  };

  return (
    <form
      onSubmit={(e) => {
        submitForm(e);
        e.preventDefault();
      }}
      id="contactForm"
      className="p-lg-4 px-3 py-4 py-md-0 "
    >
      <div className="w-100  ">
        <h4 className="text-black">Reach us at - </h4>
        {/* <p className='mb-2 text-white'>Let's  discuss your project ideas and bring it to life. Shall we?</p> */}
        <input
          // required
          type="text"
          className="w-100 py-2 px-2 rounded-3 border-0 mb-1 mt-2 inputp"
          placeholder="Your Name"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
        />
        {errors.fullName ? (
          <small className="error text-danger">{errors.fullName}</small>
        ) : (
          <div className="pb-3"></div>
        )}
      </div>

      <div className="w-100 ">
        <input
          type="text"
          className="w-100 py-2 px-2 rounded-3 border-0 mb-1 inputp"
          placeholder="abc@email.com"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email ? (
          <small className="error text-danger">{errors.email}</small>
        ) : (
          <div className="pb-3"></div>
        )}
      </div>

      <div className="w-100 ">
        {/* <input
            required
            type="number"
            className="w-100 py-2 px-2 rounded-3 border-0 mb-2"
            minLength={10}
            maxLength={10}
            placeholder="9876543210"
            name="contactNo"
            onChange={handleChange}
          /> */}
        <input
          className="w-100 inputp border-0 rounded-3 px-2 py-2"
          name="contactNumber"
          placeholder="987XXX3210"
          value={formData.contactNumber}
          type="number"
          maxLength={10}
          minLength={10}
          onChange={handleChange}
          style={{
            fontWeight: "400",
          }}
        />

        {/* <div
            className="error text-danger"
            id="contactNoError"
            style={{ height: "20px", width: "150px" }}
          ></div> */}
        {errors.contactNumber ? (
          <small className="error text-danger">{errors.contactNumber}</small>
        ) : (
          <div className="pb-3"></div>
        )}
      </div>

      <div className="w-100 ">
        <textarea
          rows="4"
          cols="50"
          className="w-100 py-2 px-2 rounded-3 border-0 mb-2 inputp"
          placeholder="Type your message here (optional)"
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
        {errors.message ? (
          <small className="error text-danger">{errors.message}</small>
        ) : (
          <div className="pb-2"></div>
        )}
      </div>

      <input
        type="submit"
        id="btn"
        className="btn  w-100 text-white px-5 rounded-5 py-2 "
        value="Send Message"
        style={{ backgroundColor: "#412201" }}
        // onClick={handleThankuPage}
      />
    </form>
  );
}
