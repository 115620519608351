import "./Animation.css";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  Navigation,
  Autoplay,
  Pagination,

  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css";



import { CustomerItems } from "./CustomerItems";

const Animation = () => {


 
    
  
  return (
    <>
      <div className=" p-md-4 p-3 ">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          
          slidesPerView={1}
          navigation
          // pagination
          // style={{ height: "510px" }}
         
          className="customer-swiper d-lg-block d-none mb-4 d-sm-none m-0 p-0  swiperheightforimac "
        >
          {CustomerItems.map((data,i) => {
            
            return (
              <SwiperSlide key={i}>
               

                <div className="">
                  <div className="px-md-5 marginleftforswiper">
                    <div className="swiper-card-txt  rounded-5 col-11 mx-5 d-flex justify-content-between ">
                      <div className="col-5">
                        <img
                          src={data.img}
                          className="img-fluid rounded-5 p-3 heightforimac "
                          alt="img"
                          // style={{height:"300px"}}
                        />
                        
                      </div>
                      <div className="col-6 me-5 mt-5">
                        <p className=" text-black  rounded-5 bg-white border col-7 p-1 text-center">
                          {data.subTitle}
                        </p>
                        
                        {data.para.map((instance, j) => {
                          return (
                            <p
                              style={{ textAlign: "justify" }}
                              className="pt-3 col-10"
                              key={j}
                            >
                              {instance.p}
                            </p>
                          );
                        })}
                    
                        <h5 className="fw-bold col-7 mt-2">{data.title}</h5>
                      </div>
                    </div>
                    
                   
                  </div>
                 
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* {/ ----------------------------------MOBILE-VIEW---------------------------------- /} */}
       
     
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}  
          slidesPerView={1}
          
          className=" d-lg-none  d-sm-block  "
          autoplay={{
            delay: 2000,
            
          }}
          style={{height:"700px"}}
        >
          {CustomerItems.map((data, i) => {
            return (
              <>
                <SwiperSlide key={i}>
                  <div className="py-3 p-4">
                    <div
                      
                      className="img2div d-lg-none d-sm-block "
                    >
                      <img
                        src={data.img}
                        alt="Project Img"
                        className="img-fluid w-100 rounded-5 p-4"
                        
                      />
                      <div className=" mx-4">
                        <p className="text-black rounded-5 bg-white border text-center fontsizetitle col-9 p-1   mx-3">
                          {data.subTitle}
                        </p>
                        {data.para.map((instance, j) => {
                          return (
                            <p
                              style={{ textAlign: "justify" }}
                              className="col-10  fontmob mt-1 mx-3"
                              key={j}
                            >
                              {instance.p}
                            </p>
                          );
                        })}
                        <h5 className="fw-bold mx-3 col-7 mt-2">
                          {data.title}
                        </h5>
                      </div>
                    </div>

                    
                  </div>
                </SwiperSlide>
              </>
            );
          })}
          
        </Swiper>
     
      </div>
    

      

     

          
        
    </>
  );
};

export default Animation;