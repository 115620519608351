import { Card } from "react-bootstrap";
import "./Technologies.css";

import { techItemsArray } from "./TechItems";

export default function Technologies() {
  let width = window.innerWidth;
  let number;
  if (width > 480) {
    number = 3;
  } else {
    number = 3;
  }

  return (
    <>
      <div className="pt-lg-2">
        <div className=" d-flex flex-wrap justify-content-between mb-md-5 my-2 mt-md-0 tech-card-section ">
          {techItemsArray.map((data, i) => {
            return (
              <>
                {i < number ? (
                  <div
                    className="col-md-4 col-12 my-2 px-xl-5 px-lg-3 px-1"
                    key={i}
                  >
                    <div className="tech-card">
                      <div className="unflipped flip">
                        <Card className="rounded-4 border-0">
                          <Card.Img
                            loading="lazy"
                            alt="Card Img"
                            variant="top"
                            src={data.img}
                            height={300}
                          />
                        </Card>
                        <div className="card-text text-white col-10 ">
                          <div className="bg-white rounded shadow ">
                            <h6 className="text-dark title-heading p-md-3 pt-2 pb-2">
                              {data.title}
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className="flipped-card d-none flip">
                        <Card className="border-radius">
                          <Card.Body>
                            <div className="inside-card mt-md-3 p-4">
                              
                              <h5>{data.flippedTitle}</h5>
                              <p className="fontsizefortext">{data.flippedText}</p>
                            </div>
                            <div className="card-flip-text  col-10 ">
                              <div className="bg-white rounded shadow ">
                                <h6 className="text-dark title-heading p-md-3 pt-2 pb-2">
                                  {data.title}
                                </h6>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
