import "./Footer.css";

import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

import phone from "../images/phone.svg"
import message from "../images/message.svg"
import Address from "../images/Address.svg"

export default function Footer() {
  return (
    <div className="footer-bg ">
      <div className=" d-md-flex flex-wrap justify-content-between align-items-center foot-pad">
      


        <div className="d-flex flex-wrap justify-content-initial gap-3 align-items-center">
          <div className=" logo-txt mt-2 logo col-md-2 col-12 mx-md-3">
           

            <img
              src={require("../images/logo-dark.png")}
              alt="Logo"
              className="img-fluid mb-1 img "
           
            />
           
          </div>
          <div className="vr text-white d-md-block d-none"></div>
          {/* Menu  */}
          <div className=" menulinks col-12 col-md-6">
            <p className="text-white px-lg-0 px-3">
              A leading IT company that provides best of technology for your
              business to achieve it’s goals.
            </p>
          </div>
        </div>

        {/* Social links  */}
        <div className="col-lg-3  col-md-4 text-md-center social-section">
          <h5 className="text-white text-center  ps-2 mt-lg-2 ps-md-0">
            Social Links
          </h5>
          <div className="d-flex flex-wrap justify-content-center social">
            <div className="insta">
              <Link
                to="https://www.instagram.com/datartinfotech/?igsh=MWtseTJkc2o2NHpnOA%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram fill="#D3D3D3" />
              </Link>
            </div>
            <div className="facebook">
              <Link
                to="https://www.facebook.com/datartinfotech"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF fill="#D3D3D3" />
              </Link>
            </div>
            <div className="linkedin">
              <Link
                to="https://in.linkedin.com/company/datart-solutions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn fill="#D3D3D3" />
              </Link>
            </div>
            <div className="twitter">
              <Link
                to="https://twitter.com/DatartSolutions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../images/twitter-svg.png")}
                  alt="Twitter"
                  className="img-fluid w-100"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <h5 className="text-center text-white">Contact Info</h5>

      <div className="d-flex flex-wrap justify-content-center gap-4 mt-3 mt-lg-0">

        <a href="tel:+91 9552238055" className="text-decoration-none text-white" ><img src={phone} className="img-fluid" /> &nbsp; +91 9552238055</a>


        <a href="mailTo:hello@datartinfotech.com" className="text-decoration-none text-white" ><img src={message} className="img-fluid" /> &nbsp; hello@datartinfotech.com</a>

      </div>
      <p  className="text-decoration-none text-white  text-center mt-3" > <img src={Address} className="img-fluid" /> &nbsp; 203, Pentagon 2, Magarpatta, Hadapsar-411028</p>

      <div className="text-center container mt-4 ">
        <p className="secondary-light-color mb-0 pb-3  small-size">
          Copyright 2024. All Rights Reserved. Designed & Developed by Datart
          Infotech
        </p>
      </div>
    </div>
  );
}
