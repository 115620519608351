import { useState, useEffect, useRef } from "react";
import "./home.css";
import { FaCheckCircle } from "react-icons/fa";
import axios from 'axios';
import graphicDesign from '../images/homeImages/graphic-design.svg'
import global from '../images/homeImages/global.svg'
import network from '../images/homeImages/global-network.svg'
import dollar from '../images/homeImages/dollar.svg'
import developer from '../images/homeImages/developer.svg'
import chat from '../images/homeImages/chat.svg'
// import Banner from "../images/Banner-bg.png";
import Banner from '../images/bgbanner.png'
import BannerMob from "../images/homeImages/Banner.png";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import CssSwiper from "../components/Common/CssSwiper";
import Technologies from "../components/Common/Technologies";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Modal } from "react-bootstrap";
import { LiaEdit } from "react-icons/lia";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import Testimonials from "../components/Common/Testimonials";
import Animation from "../components/Common/Animation";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { FaArrowRightLong } from "react-icons/fa6";

import a from "../images/techImages/googleapp.png"
import b from "../images/techImages/googleseo.png"


import { CiLocationOn } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import Contactform from "../components/Common/Contactform";
export default function Home() {
  let changeImg = window.innerWidth;

  

  const sectionRef = useRef(null);
  const [country, setCountry] = useState('');
  
    const apiKey = "AIzaSyDC97e4cayS1sR0iq8OREzSIMQHjeN6eUI";

     const location = useLocation();
     console.log(location.pathname);
     const path = location.pathname;

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords

            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
            );
           
            


             
            //  const country = response.data.results[0].address_components.find(
            //   (component) => component.short_name
            // );

            
           
            const addressComponents = response.data.results[0].address_components;
           

            const countryComponent = addressComponents.find((component) =>
              component.types.includes("country")
            );

            if (countryComponent) {
              const countryShortName = countryComponent.short_name;
              
              setCountry(countryShortName)
            } else {
              console.log("Country component not found");
            }
            
          } catch (error) {
            console.error("Failed to fetch data:", error);
          }
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );  
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  }, []);


  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const [modal, setModal] = useState(false);

  const handleContactClick = () => {
    setModal(true);
  };

  // navigation

  const navigate = useNavigate()

  // form
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    // message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handlePhoneChange = (value) => {
    const maxDigits = 13;

    if (value?.replace(/\D/g, '').length > maxDigits) {
      setErrors({ ...errors, contactNumber: `Contact number cannot exceed ${10} digits.` });
    } else {
      setErrors({ ...errors, contactNumber: '' });
    }
    setFormData({ ...formData, contactNumber: value });

    
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.fullName?.trim()) {
      errors.fullName = "Full Name is required";
      isValid = false;
    }

    if (!formData.email?.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.contactNumber?.trim()) {
      errors.contactNumber = "Contact Number is required";
      isValid = false;
    } else if (!/^\+\d{1,4}\d{10}$/.test(formData.contactNumber)) {
      errors.contactNumber = "Contact Number is invalid";
      isValid = false;
    }

    

    return { isValid, errors };
  };
 
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const { isValid, errors } = validateForm();
   
  //   if (isValid) {
  //     const config = {
        
  //       Host: "smtp.elasticemail.com",
  //       Username: "yashtatiya@datartinfotech.com",
  //       Password: "982BFD409389CF7604BF8757A0345C4FEE19",
  //       To: "yashtatiya@datartinfotech.com",
  //       From: "yashtatiya@datartinfotech.com",
  //       Subject: `New enquiry received from ${formData.fullName}`,
  //       Body: ` 
  //         Full Name : ${formData.fullName} <br />
  //         Email Id : ${formData.email} <br />
  //         Phone Number : ${formData.contactNumber} <br />
         
  //       `,
  //     };
      

  //     if (window.Email) {
  //       window.Email.send(config)
  //         .then(() => {
           
  //           navigate("/thank-you")

  //           if (window.fbq) {
  //             window.fbq("track", "Lead");
  //           }

  //           setModal(false);

  //           setFormData({
  //             fullName: "",
  //             email: "",
  //             contactNumber: "",
              
  //           });
  //         })
  //         .catch((err) => {
  //           toast.error("Oops! Something went wrong.");
  //           console.error(err);
  //         });
  //     }
  //   } else {
  //     setErrors(errors);
  //     console.log("Form validation failed");
  //   }
  // };

   const handleThankuPage = () => {
     navigate("/thank-you");
   };
  const handleSubmit = async(e)=>{
 e.preventDefault();
    const { isValid, errors } = validateForm();

    if (isValid) {
      const data = {
        fullName: formData.fullName,
        email: formData.email,
        contactNumber: formData.contactNumber,
        message: formData.message || "No message provided.",
      };

      try {
        const response = await axios.post(
          `https://server-dot-apenterpirse-test.el.r.appspot.com/sendcontactmail`, data
        );

        if (response.status === 200) {
          toast.success(
            "Thank You for reaching out to us. We'll get back to you shortly."
          );
          console.log("responseapi",response);
          window.scrollTo(0, 0);
          setFormData({
            fullName: "",
            email: "",
            contactNumber: "",
            message: "",
            captcha: "",
          });
          // setInputText("");
          // setCaptchaValid("");
          // setErrors({ ...errors, captcha: "" });
          // handleThankuPage();
           navigate("/thank-you");
        } else {
          toast.error("Oops! Something went wrong.");
        }
      } catch (err) {
        // toast.error("Oops! Something went wrong.");
        console.error(err);
      }
    } else {
      setErrors(errors);
      console.log("Form validation failed");
    }
  }

  const handleCloseModal = () => {
    setModal(false)
    setFormData({
      fullName: "",
      email: "",
      contactNumber: "",
    })
  }
  // for modal showing

  useEffect(() => {
    setModal(true);
  }, []);
  return (
    <>
      <Header handleClick={handleContactClick} />
      {/* Banner  */}
      {/* <div className="banner">
        <img
          loading="eager"
          src={changeImg > 480 ? Banner : BannerMob}
          alt="Banner Img"
          className="w-100 banner-img"
        />
        <div className="banner-box">
          <div className="inner-box">
            <h5 className="stats-color text-white">
              <span className="text-black">Innovate. Integrate. Excel</span>
            </h5>
            <h1 id="h1" className="" style={{ color: "#D27E3D" }}>
              Transform your business with
              <span style={{ color: "#7E481B" }}> Datart Infotech</span> this
              Thanksgiving
            </h1>
            <button
              onClick={handleContactClick}
              style={{ backgroundColor: "#412201" }}
              className="p-2 text-white cursor-pointer text-decoration-none rounded-5 col-md-3 mt-3"
            >
              Know More
            </button>
          </div>
        </div>
      </div> */}

      <div className="bannerrole d-md-block d-none">
        <div className="d-flex flex-wrap align-items-center bannerbackgroundlanding">
          <div className="col-md-7 ">
            <div className="banner-box">
              <div className="">
                <h4 className="mx-1 ">Innovate. Integrate. Excel </h4>
                <h1 id="h1" className="fontforh1" style={{ color: "#D27E3D" }}>
                  Transform your business with
                  <span className="gradient-text">
                    {" "}
                    Datart Infotech
                  </span> this <br />
                  <span className="gradient-text">Holiday Season</span>
                </h1>

                <button
                  onClick={handleContactClick}
                  style={{ backgroundColor: "#412201" }}
                  className="paddingforbtn  text-white cursor-pointer text-decoration-none rounded-5 col-md-4 mt-3"
                >
                  Know More <FaArrowRightLong className="mx-1" />
                </button>
              </div>
            </div>
            {/* <div className="d-flex justify-content-evenly mt-md-5 mx-md-4 p-3 ">
              <div className="col-3">
                <h4>10+ Industries</h4>
                <p>Covered across the globe</p>
              </div>
              <div className="col-3">
                <h4>95%</h4>
                <p>Customer Satisfaction Rate</p>
              </div>
              <div className="col-4">
                <h4>100%</h4>
                <p>
                  Scalability Guaranteed, ensuring complete future-readiness.
                </p>
              </div>
            </div> */}
            {/* <div className=" clientimgdiv p-2 mt-md-3">
              <img

                className="img-fluid  col-7"
                alt="bannclient"
              />
              <p className="mt-3 mx-2 ">
               
                Trusted by Industry Leaders Across <br />
                10+ Sectors
              </p>
            </div> */}
          </div>
          <div className="col-md-5  ">
            <img
              src={require("../images/sidebannerbg.png")}
              className="img-fluid  p-md-4 pb-md-0"
            />
          </div>
        </div>
      </div>
      <div className="bannerrole d-md-none d-block">
        <div className="d-flex flex-wrap align-items-center bannerbackgroundlanding">
          <div className="col-md-7 col-12">
            <div className="banner-box">
              <div className="">
                <h6 className="mx-1 pt-4 ">Innovate. Integrate. Excel </h6>
                <h1 id="h1" className="" style={{ color: "#D27E3D" }}>
                  Transform your business with
                  <span style={{ color: "#7E481B" }}>
                    {" "}
                    Datart Infotech
                  </span>{" "}
                  this <br />
                  <span style={{ color: "#7E481B" }}>Holiday Season</span>
                </h1>
              </div>
            </div>
            {/* <div className="d-flex justify-content-evenly mt-md-5 mx-md-4 p-3 ">
              <div className="col-3">
                <h4>10+ Industries</h4>
                <p>Covered across the globe</p>
              </div>
              <div className="col-3">
                <h4>95%</h4>
                <p>Customer Satisfaction Rate</p>
              </div>
              <div className="col-4">
                <h4>100%</h4>
                <p>
                  Scalability Guaranteed, ensuring complete future-readiness.
                </p>
              </div>
            </div> */}
            {/* <div className=" clientimgdiv p-2 mt-md-3">
              <img

                className="img-fluid  col-7"
                alt="bannclient"
              />
              <p className="mt-3 mx-2 ">
               
                Trusted by Industry Leaders Across <br />
                10+ Sectors
              </p>
            </div> */}
          </div>
          <div className="col-md-5 col-12 pt-5 mt-5 ">
            <img
              src={require("../images/sidebannerbg.png")}
              className="img-fluid  p-md-4 pb-md-0"
            />
            <button
              onClick={handleContactClick}
              style={{ backgroundColor: "#412201" }}
              className="p-2 text-white cursor-pointer text-decoration-none rounded-5 col-6 mt-3 mx-4 px-4"
            >
              Know More <FaArrowRightLong className="mx-1" />
            </button>
          </div>
        </div>
      </div>

      {/* section second */}
      <div className="container mt-lg-5  mt-3 mb-5">
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <div className="col-lg-6 col-12">
            <h1 className="mt-3 mt-lg-0 d-md-none d-block mb-3">Who We Are?</h1>
            <img
              src={require("../images/contactsidebg.png")}
              alt=""
              className="img-fluid rounded-2"
            />
          </div>
          <div className="col-lg-6 col-12 px-lg-5">
            <h1 className="mt-0 mt-lg-0 d-md-block d-none ">Who We Are?</h1>
            <p className="mt-4 col-md-10 col-12">
              At Datart Infotech, we are passionate about creating stunning,
              functional and user-friendly custom websites and Web Applications.
              Based in Pune, India, our team of experts delivers world-class wed
              development services to clients across the globe. With a focus on
              innovation, quality and customer satisfaction, we turn your
              digital dreams into reality.
            </p>
            <h5 className="mt-4">
              Discuss your vision with our design team and see how we can bring
              it to life
            </h5>
            <div className="d-flex justify-content-md-start">
              <button
                onClick={handleContactClick}
                style={{ backgroundColor: "#412201" }}
                className="p-2 text-white cursor-pointer text-decoration-none rounded px-5 mt-4"
              >
                Get a FREE expert advice
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* why choose us  */}

      <div className="choose-us-bg">
        <div className="container mb-lg-5">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="col-lg-5 col-12">
              <h1 className="text-md-center mb-md-2 mb-3">Why Choose Us?</h1>
              <p className="text-center d-none d-md-block">
                Elevate your business with our unmatched IT solutions, crafted
                to empower your growth, streamline operations, enhance
                efficiency ,to achieve remarkable success. We provide
                personalised support and guidance tailored to your unique need.
              </p>
            </div>
            <div className="col-lg-6 col-12 mb-md-0 mb-3">
              <img
                loading="lazy"
                src={require("../images/4.png")}
                alt=""
                className="img-fluid w-100 rounded-2"
              />
            </div>
            <p className=" d-md-none d-block">
              Elevate your business with our unmatched IT solutions, crafted to
              empower your growth, streamline operations, enhance efficiency ,to
              achieve remarkable success. We provide personalised support and
              guidance tailored to your unique need.
            </p>
          </div>
        </div>

        <div className="inside-data mb-lg-5 mx-auto mt-md-0 mt-4">
          <div className="container d-flex flex-wrap justify-content-center align-items-center ">
            <div className="col-md-4 approches px-md-5 pe-md-0">
              <img src={graphicDesign} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Customer-Centric Approach</h4>
              <p className="secondary-light-color">
                Prioritizing your needs and goals to deliver tailored solutions.
              </p>
            </div>
            <div className="col-md-4 approches px-md-5 pe-md-0">
              <img src={network} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Global Expertise</h4>
              <p className="secondary-light-color">
                Leveraging international experience to bring innovative
                perspectives.
              </p>
            </div>
            <div className="col-md-4 approches px-md-5 pe-md-0">
              <img src={global} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Focus on Results</h4>
              <p className="secondary-light-color">
                Committed to achieving measurable success for your projects.
              </p>
            </div>
          </div>
        </div>

        <div className="inside-data">
          <div className="container d-flex flex-wrap justify-content-center align-items-center">
            <div className="col-md-4 approches px-md-5 pe-md-0">
              <img src={dollar} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Reasonable Cost</h4>
              <p className="secondary-light-color">
                Providing high-quality services at competitive prices.
              </p>
            </div>
            <div className="col-md-4 approches px-md-5 pe-md-0">
              <img src={developer} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Seamless Development</h4>
              <p className="secondary-light-color">
                Ensuring a smooth and efficient development process.
              </p>
            </div>
            <div className="col-md-4 approches px-md-5 pe-md-0">
              <img src={chat} alt="" className="img-fluid" />
              <h4 className="mb-3 pt-3">Ongoing Support</h4>
              <p className="secondary-light-color">
                Offering continuous assistance and maintenance post-launch.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* stats */}
      {/* <Stats /> */}

      {/* offer1section */}
      <div
        // style={{ backgroundColor: "#F2F4F7" }}
        className="container rounded-4  mt-md-4 backgroundoffer "
      >
        <div className="text-center p-2">
          <p
            className="textcolor pt-lg-0 pt-4 saleformob"
            style={{ fontFamily: "Brush Script MT", fontSize: "40px" }}
          >
            Sale upto
          </p>
          <h1
            className="fontforoff textcolor saleoffformob"
            style={{ fontFamily: "Copperplate", fontSize: "110px" }}
          >
            20% OFF
          </h1>
          <p
            className="textcolor onselectedmob"
            style={{ fontFamily: "Outfit", fontSize: "24px" }}
          >
            ON SELECTED SERVICES
          </p>
          <div className="d-flex flex-wrap   justify-content-center">
            <img src={a} className="d-sm-none d-block px-2" />
            <img src={b} className="d-sm-none d-block px-2" />
          </div>

          <p style={{ textAlign: "center" }} className=" mt-md-0 mt-2">
            Explore our exclusive holiday season offer to boost your online
            presence
            <br /> and achieve success in the digital realm.
          </p>

          <div className=" mt-md-4 ">
            <button
              className="py-2 rounded col-md-3  cursor-pointer bg-white buttontextcolor px-lg-5 px-5  availoffer"
              onClick={handleContactClick}
            >
              AVAIL OFFER
            </button>
          </div>
        </div>
      </div>

      {/* Uncover Expertise */}
      <div className="technologies-bg mt-md-4 mt-4">
        <div className="container p-4 tech ">
          <div className="col-12 tech-heading text-center">
            <div className="pt-0 pt-md-0">
              <h1 className="pt-3 " style={{ color: "#412201" }}>
                From Vision to Reality
              </h1>
            </div>
            <p
              className=" text-center mb-lg-0 mb-0 pt-2 "
              style={{ color: "1c1c1c" }}
            >
              Comprehensive Web Development Services for Businesses Around the
              Globe.
            </p>
          </div>

          <Technologies />
        </div>
      </div>

      <div className="bg-needs">
        <div className="container">
          <div className=" pb-5">
            <div className="container">
              <h1 className="text-center pt-5">Our Process</h1>
              <p className="text-center my-3 ">
                Partner with Datart Infotech for a website that exceeds
                expectations
              </p>

              <div className="d-flex position-relative flex-wrap justify-content-around mt-md-5">
                <div className="col-lg-2 col-md-2 col-12 mx-auto need-pad py-2 py-md-0  pe-md-3 pe-lg-0 pe-0 ">
                  <img
                    src={require("../images/div.box-icon-5.png")}
                    alt="Needs Img"
                    width="50%"
                    className="img-fluid pad-img ms-2 col-3 col-md-7 "
                  />
                  <h4 className="ms-2 py-md-2">IDEATE</h4>
                  <p className="secondary-light-txt ms-2 pe-md-2">
                    We creatively collaborate to brainstorm and refine solutions
                    for groundbreaking advancements.
                  </p>
                </div>
                <div className="col-lg-2 col-md-2 col-12 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0">
                  <img
                    src={require("../images/div.box-icon-4.png")}
                    alt="Needs Img"
                    width="50%"
                    className="img-fluid pad-img ms-2 col-3 col-md-7"
                  />
                  <h4 className="ms-2 py-md-2">DESIGN</h4>
                  <p className="secondary-light-txt ms-2 pe-md-2 ">
                    We craft visionary blueprints that breathe life into your
                    ideas just like tangible concepts.
                  </p>
                </div>
                <div className="col-lg-2 col-md-2 col-12 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0">
                  <img
                    src={require("../images/div.box-icon-3.png")}
                    alt="Needs Img"
                    width="50%"
                    className="img-fluid pad-img ms-2 col-3 col-md-7"
                  />
                  <h4 className="ms-2 py-md-2">DEVELOP</h4>
                  <p className="secondary-light-txt ms-2 pe-md-2">
                    The most interesting part where we transform concepts into
                    cutting-edge, functional realities.
                  </p>
                </div>
                <div className="col-lg-2 col-md-2 col-12 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0">
                  <img
                    src={require("../images/icon4.png")}
                    alt="Needs Img"
                    width="50%"
                    className="img-fluid pad-img ms-2 col-3 col-md-7"
                  />
                  <h4 className="ms-2 py-md-2">TEST</h4>
                  <p className="secondary-light-txt ms-2 pe-md-2">
                    We ensures every aspect of our creation meets the highest
                    standards, performance and reliability.
                  </p>
                </div>
                <div className="col-lg-2 col-md-2 col-12 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0">
                  <img
                    src={require("../images/div.box-icon-1.png")}
                    alt="Needs Img"
                    width="50%"
                    className="img-fluid pad-img ms-2 col-3 col-md-7"
                  />
                  <h4 className="ms-2 py-md-2">DEPLOY</h4>
                  <p className="secondary-light-txt ms-2 pe-md-2">
                    Witness your innovations take flight as we expertly launch
                    them into the digital realm.
                  </p>
                </div>
                <div className="col-lg-2 col-md-2 col-12 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0 ">
                  <img
                    src={require("../images/icon6.png")}
                    alt="Needs Img"
                    width="50%"
                    className="img-fluid pad-img  ms-2 col-3 col-md-7"
                  />
                  <h4 className="ms-2 py-md-2">MAINTAIN</h4>
                  <p className="secondary-light-txt ms-2 pe-md-2">
                    Rest easy, as we tend to nurturing and safeguarding your
                    creations for enduring excellence.
                  </p>
                </div>

                <hr className=" hrStyle d-none d-md-block" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="projects" ref={sectionRef} className="swiper-background-image">
        <div className="container">
          <div className="text-center">
            <h1 className="pt-5 text-md-center mb-lg-3 text-start">
              Our Work Speaks for Itself
            </h1>
            <p className="text-muted text-md-center text-start">
              Take a look at some of our recent projects that showcase our
              expertise and creativity. From sleek corporate websites, dynamic{" "}
              <br /> e-commerce platforms to custom web applications, our
              portfolio highlights our versatility and commitment to excellence
            </p>
          </div>
        </div>
        <Animation />
      </div>

      <div></div>

      {/* offer2 */}

      <div className="background-special-offer p-lg-5 mt-md-0 mt-4 ">
        <div className="container">
          <div className="special-offer rounded-5 px-md-0 p-md-0 pb-md-4 p-5">
            <div className="d-flex flex-wrap justify-content-evenly">
              <div className="col-md-6 col-12 mt-lg-5">
                <h1 className="d-sm-block d-none">
                  Discover the incredible offer <br />
                  <span>
                    waiting for you this holiday <br /> season!
                  </span>
                </h1>
                {/* <div className="d-flex flex-wrap justify-content-between align-items-center mt-4">
                  <div className="col-lg-1 col-1">
                    <FaCheckCircle fill="#017B9F" size={25} />
                  </div>
                  <h4
                    style={{ color: "#017B9F" }}
                    className="mt-2 col-lg-11 col-10 fw-semibold"
                  >
                    {" "}
                    Upto 20% Off
                  </h4>
                </div>
                <div className="d-flex flex-wrap justify-content-between align-items-center mt-1 mt-md-0">
                  <div className="col-lg-1 col-1">
                    <FaCheckCircle fill="#017B9F" size={25} />
                  </div>
                  <h4
                    style={{ color: "#017B9F" }}
                    className="mt-2 col-lg-11 col-10 fw-semibold"
                  >
                    3 Months Free Maintenance
                  </h4>
                </div>
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                  <div className="col-1">
                    <FaCheckCircle fill="#017B9F" size={25} />
                  </div>
                  <h4
                    style={{ color: "#017B9F" }}
                    className="mt-2  col-lg-11 col-10 fw-semibold"
                  >
                    Custom Designs
                  </h4>
                </div> */}
                <h1 className="d-sm-none d-block">
                  Discover the incredible offer waiting for you this holiday
                  season!
                </h1>
                <div
                  className="col-md-6 mt-md-4 d-sm-block d-none "
                  onClick={handleContactClick}
                >
                  <button
                    // style={{ backgroundColor: "#017B9F" }}
                    className="py-2 rounded w-100  cursor-pointer bg-white buttontextcolor"
                  >
                    Explore Your Offer
                    <FaArrowRightLong className="mx-1" />
                  </button>
                </div>
              </div>
              <div className="col-md-5 pt-lg-0 pt-3 ">
                <img
                  src={require("../images/sale.png")}
                  alt=""
                  className="img-fluid"
                />
                <div
                  className=" mt-md-4 d-sm-none d-block pt-lg-0 pt-4 "
                  onClick={handleContactClick}
                >
                  <button
                    // style={{ backgroundColor: "#017B9F" }}
                    className="py-2 rounded w-100  cursor-pointer bg-white buttontextcolor"
                  >
                    Explore Your Offer
                    <FaArrowRightLong className="mx-1" />
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="col-md-3 mx-auto" onClick={handleContactClick}>
              <button
                style={{ backgroundColor: "#017B9F" }}
                className="py-3 rounded w-100 text-white cursor-pointer "
              >
                Claim Your Discount Now !
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div className="container">
        <h1 className="text-center mt-5">Success Stories</h1>
        <p className="text-center my-3 ">
          Don’t just take our word for it. Here’s what our clients have to say
        </p>
        <Testimonials />
      </div>

      {/* <ClientSpotLight /> */}
      <div className="text-center my-5">
        <h1 className="">Client Spotlights</h1>
        <p className="p-md-0 p-3">
          Showcasing our exceptional partnerships, inspiring growth and
          innovation.
        </p>
      </div>
      <CssSwiper />

      {/* <div
        style={{ backgroundColor: "#F6FCFF" }}
        className="container rounded-4  my-5"
      >
        <div className="d-flex flex-wrap justify-content-between align-items-center p-3 pe-lg-0">
          <div className="col-lg-7 col-12 px-lg-5">
            <h1 className="mb-4">Ready to get started?</h1>
            <p className="tetx-center">
              Lets discuss your project and see we can help you{" "}
              <br className="d-none d-md-block" />
              achieve your online goals.
            </p>

            <div className="col-lg-7 text-center d-md-block d-none">
              <button
                onClick={handleContactClick}
                style={{
                  backgroundColor: "#F2F4F7",
                  color: "#2293BB",
                  fontWeight: 600,
                }}
                className="p-2 rounded w-100 mt-4"
              >
                Schedule Your Free Design Consultation Today{" "}
                <HiOutlineArrowNarrowRight />
              </button>
            </div>
          </div>
          <div className="col-lg-4 mt-3 mt-lg-0 col-12">
            <img
              src={require("../images/sectionthird.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-lg-7 text-center d-md-none d-block">
            <button
              onClick={handleContactClick}
              style={{
                backgroundColor: "#F2F4F7",
                color: "#2293BB",
                fontWeight: 600,
                fontSize: "14.8px",
              }}
              className="p-2 rounded w-100 mt-4"
            >
              Schedule Your Free Design Consultation Today{" "}
              <HiOutlineArrowNarrowRight />
            </button>
          </div>
        </div>
      </div> */}

      {/* Work */}

      <div className="container"></div>

      {/* special offers */}

      {/* <Success Stories> */}

      {/* Inovation Driven */}
      <div className="inovation-driven p-3 ">
        <div className="container">
          <h1 className="text-center mt-md-0 mt-3">
            Innovation driven by new <br />
            <span style={{ color: " #D78028 " }}>Technologies</span>
          </h1>
          <p className="text-center text-muted pt-3">
            Effortlessly manage your cloud and on-premises systems with our
            comprehensive support for infrastructure <br /> and applications.
          </p>
        </div>
      </div>

      {/* Technologies */}

      <div
        style={{ backgroundColor: "#FFF9F3" }}
        className="container rounded-4 p-md-5 mb-5 p-3"
      >
        <div className="d-flex flex-wrap justify-content-center align-items-center pt-4">
          <div className="col-md-9 col-12">
            <div className="imgs d-flex flex-wrap justify-content-between overflow-auto">
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/javascript.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Javascript</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/react.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">React JS</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/angular.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Angular</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/html.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">HTML5</p>
              </div>
              <div className="col-4 col-md-2 pb-3 pb-md-0 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/Wordpresstech.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">WordPress</p>
              </div>
              <div className="col-4 col-md-2 text-center pe-2">
                <img
                  src={require("../images/solutionPageImages/techUsed/figma.png")}
                  alt="Img"
                  width="100%"
                  className="img-fluid "
                />
                <p className="secondary-light-txt ">Figma</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* third section */}
      <div className="container mt-lg-5  mt-3 mb-5 d-md-block d-none">
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <div className="col-lg-5 col-12">
            <h1 className="mt-0 mt-lg-0 ">
              Get Your
              <span style={{ color: " #A36526" }}>
                {" "}
                Free Design Consultation Today!
              </span>
            </h1>
            <p style={{ textAlign: "justify" }} className="mt-4 ">
              At Datart Infotech, every great project starts with a great
              conversation. That why we are offering free design consultation to
              help you explore the possibilities for your web development needs.{" "}
              <br />
            </p>

            <ul>
              <li>
                <b>Personalized Assessment:</b> We’ll analyze your requirements
                and provide tailored solutions. <br />
              </li>
              <li>
                <b>Expert Advice:</b> Get insights from our experienced
                designers and developers. <br />
              </li>
              <li>
                <b>No Obligation:</b> There’s no commitment required – just
                valuable advice for your project. <br />
              </li>
            </ul>

            <h4
              onClick={handleContactClick}
              className="contact-txt d-md-block d-none"
            >
              Schedule Your Free Consultation Now <HiOutlineArrowNarrowRight />
            </h4>
          </div>
          <div className="col-lg-6 col-12">
            <img
              src={require("../images/contactsidebg.png")}
              alt=""
              className="img-fluid rounded-2"
            />
          </div>
          <h4
            onClick={handleContactClick}
            className="contact-txt d-block d-md-none pt-2"
            style={{ fontSize: "18.5px" }}
          >
            Schedule Your Free Consultation Now <HiOutlineArrowNarrowRight />
          </h4>
        </div>
      </div>

      <div className="container mt-lg-5  mt-3 mb-5 d-block d-md-none">
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <div className="col-lg-5 col-12">
            <h1 className="mt-0 mt-lg-0 ">
              Get Your
              <span style={{ color: " #A36526" }}>
                {" "}
                Free Design Consultation Today!
              </span>
            </h1>
            <div className="col-lg-6 col-12">
              <img
                src={require("../images/contactsidebg.png")}
                alt=""
                className="img-fluid rounded-2"
              />
            </div>

            <p style={{ textAlign: "justify" }} className="mt-4 ">
              At Datart Infotech, every great project starts with a great
              conversation. That why we are offering free design consultation to
              help you explore the possibilities for your web development needs.{" "}
              <br />
            </p>

            <ul>
              <li>
                <b>Personalized Assessment:</b> We’ll analyze your requirements
                and provide tailored solutions. <br />
              </li>
              <li>
                <b>Expert Advice:</b> Get insights from our experienced
                designers and developers. <br />
              </li>
              <li>
                <b>No Obligation:</b> There’s no commitment required – just
                valuable advice for your project. <br />
              </li>
            </ul>

            <h4
              onClick={handleContactClick}
              className="contact-txt d-md-block d-none"
            >
              Schedule Your Free Consultation Now <HiOutlineArrowNarrowRight />
            </h4>
          </div>

          <h4
            onClick={handleContactClick}
            className="contact-txt d-block d-md-none pt-2"
            style={{ fontSize: "18.5px" }}
          >
            Schedule Your Free Consultation Now <HiOutlineArrowNarrowRight />
          </h4>
        </div>
      </div>

      {/* contactform */}
      <div className="">
        <div className="bgcontact">
          <div className="c d-flex flex-wrap  cntc align-items-center ">
            <div className="col-md-7 px-xl-5 px-md-2 my-md-5 mb-3 mt-md-5 mt-4">
              <div className="bg-back-contact">
                <div className="text-center text-white top-contact ">
                  <h1 style={{ color: " #A36526" }}>Connect with Us</h1>
                  <p className=" px-3 pforimac text-black">
                    Connect with us to unlock endless possibilities for your
                    business growth and witness our <br />
                    commitment to your success firsthand.
                  </p>
                </div>
                <div className="text-black px-md-5 px-sm-3 pb-lg-5 p-md-4 p-3">
                  <div className="contact-svg pb-md-3 pb-1 d-flex flex-wrap">
                    <div>
                      <BsTelephone className="me-3 " />
                    </div>
                    <div>
                      <a
                        href="tel:9552238055"
                        className="text-decoration-none text-black fontforcontact"
                      >
                        +91 9552238055
                      </a>
                    </div>
                  </div>
                  <div className="contact-svg pb-md-3  pb-1 d-flex mailFold flex-wrap">
                    <div>
                      <AiOutlineMail className="me-3" />
                    </div>
                    <div>
                      <a
                        href="mailto:hello@datartinfotech.com"
                        className="text-decoration-none text-black fontforcontact"
                      >
                        hello@datartinfotech.com
                      </a>
                    </div>
                  </div>
                  <div className="contact-svg pb-md-5  pb-1 d-flex ">
                    <div>
                      <CiLocationOn className="me-3 " />
                    </div>
                    <p className="fontforcontact">
                      203, Pentagon 2, Magarpatta, Hadapsar-411028
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 px-xl-5 px-md-2 my-md-4 my-3 ">
              <div className="bg-back-contact">
                {/* <ContactForm /> */}
                <Contactform />
              </div>
            </div>

            {/* Map  */}
            <div className="col-md-10 col-12 mx-auto bg-back-contact">
              {path === "/contact-us" ? (
                <div className=" p-lg-4 p-md-4 p-3">
                  <h5 className="text-white py-3 fw-light">Visit us at -</h5>
                  <div
                    className="mx-auto pb-5 mapDiv rounded"
                    style={{
                      textDecoration: "none",
                      overflow: "hidden",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  >
                    <div
                      id="google-maps-canvas"
                      style={{
                        height: "100%",
                        width: "100%",
                        maxWidth: "100%",
                      }}
                    >
                      <iframe
                        title="Map"
                        style={{ height: "100%", width: "100%", border: 0 }}
                        src="https://www.google.com/maps/embed/v1/place?q=Datart+Solutions,+Pentagon,+Magarpatta,+Hadapsar,+Pune,+Maharashtra,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                      />
                    </div>
                    {/* <a className="google-map-code-enabler" href="https://www.bootstrapskins.com/themes" id="make-map-data">premium bootstrap themes</a> */}
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          "#google-maps-canvas img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <Modal className="border-0 " show={modal}>
        <Modal.Body className="modal-header-bg rounded-2">
          <div className="d-flex flex-wrap justify-content-between mt-3 ">
            <div className="col-2 ">
              <LiaEdit size={35} fill="white" className="mx-3" />
            </div>
            <p className="col-8 text-white text-center  bg-primaryfortext ">
              Please fill out the form below with your enquiry to get in touch
              with us shortly.
            </p>
            <div className="col-2 text-end ">
              <IoMdClose
                size={20}
                className="close-button-popup mt-2 cursor-pointer"
                fill="white"
                onClick={handleCloseModal}
              />
            </div>
          </div>
          <form
            className="contact-form p-md-5 p-2 pt-2 pb-2 mt-5"
            onSubmit={handleSubmit}
          >
            <div className="form-group mb-3">
              <label htmlFor="fullName">Full Name</label>
              <div style={{ height: "50px" }} className="">
                <input
                  type="text"
                  className={`form-control inputp ${
                    errors.fullName ? "is-invalid" : ""
                  }`}
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                />
                {errors.fullName && (
                  <div className="invalid-feedback">{errors.fullName}</div>
                )}
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email">Email</label>
              <div style={{ height: "50px" }}>
                <input
                  type="email"
                  className={`form-control inputp  ${
                    errors.email ? "is-invalid" : ""
                  }`}
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="contactNumber">Contact Number</label>
              <div className="form-control">
                <PhoneInput
                  placeholder=""
                  international
                  defaultCountry={country}
                  onChange={handlePhoneChange}
                  value={formData.contactNumber}
                  name="contactNumber"
                  className="phoneinput"
                  country={""}
                />
                {errors.contactNumber && (
                  <div className="invalid-feedback">{errors.contactNumber}</div>
                )}
              </div>
              {errors.contactNumber && (
                <div className="invalid-feedbackss">{errors.contactNumber}</div>
              )}
            </div>

            <div className="col-lg-7 col-12 mt-4">
              <button
                style={{ backgroundColor: "#2293BB" }}
                type="submit"
                className="p-2 rounded-5 text-white w-100"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
