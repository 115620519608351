export const techItemsArray = [
  {
    img: require("../../images/techImages/Web-portal.png"),
    title: "WEB PORTALS",

    flippedTitle: "Web Portals",
    flippedText:
      "Create impactful online experiences with our interactive platforms, connecting users effortlessly.",
  },
  {
    img: require("../../images/techImages/e-commerce.png"),
    title: "E-COMMERCE",

    flippedTitle: "E-Commerce",
    flippedText:
      "Elevate your online presence and sales potential with our robust e-commerce solutions.",
  },
  {
    img: require("../../images/techImages/erp.png"),
    title: "ERP/CRM SOLUTIONS",

    flippedTitle: "ERP/CRM SOLUTIONS",
    flippedText:
      "Strengthen customer relationships and streamline interactions with our efficient CRM solutions & Integrate and optimize your business processes for increased efficiency and productivity ERP solution.",
  },
];
